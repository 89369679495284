<template>
  <div style="position: relative; width: 100%; height: 100%; max-width: 100vw; max-height: 100vh;">
    <rapi-doc
      v-if="url" :spec-url="url" style="width: 100%; height: 100%;"
      theme="light"
      allow-authentication="false"
      show-header="false"
      allow-try="false"
      load-fonts="false"
      render-style="view"
      bg-color="#f0f0f0"
      primary-color="#780bb7"
    />
  </div>
</template>


<script>
export default {
  components: {

  },
  props: ['base', 'file'],
  data () {
    return {
      openapi: undefined,
      window,
      url: ''
    };
  },
  watch: {
    base () {
      this.fetchFile();
    }
  },
  created () {
    this.fetchFile();
  },
  methods: {
    fetchFile () {
      this.openapi = undefined;
      this.url = `/contents/${this.base}${this.base?'/':''}api.json`;
      this.$http.get(this.url)
      .then(res => {
        this.openapi = this.purgeLinks(res.data);
      })
      .catch(err => {
        console.error(err);
        return this.$router.replace('/404/index');
      });
    },
    /**
     * Purges links from endpoint parameter descriptions
     */
    purgeLinks (openapi) {
      if (openapi?.paths) {
        Object.keys(openapi.paths).forEach(path => {
          Object.keys(openapi.paths[path]).forEach(method => {
            const endpoint = openapi.paths[path][method];
            endpoint?.parameters?.forEach(param => {
              if (param?.description && typeof param.description === 'string' && param.description.indexOf('<a href') !== -1) {
                param.description = param.description.slice(0, param.description.indexOf('<a href'));
              }
            });
          });
        });
      }
      return openapi;
    }
  },
};
</script>


<style lang="scss">
@import 'larva';
 pre[class*="language-"] {
    padding: 0.4rem;
    line-height: 1.2 !important;
    font-size: 0.78rem !important;
    tab-size: 2;
    text-shadow: 0 0
 }
 pre[class*="language-"] a {
   color: $light;
   text-decoration: underline;
 }
 .page {
   margin: 1rem;
 }
</style>
