<template>
  <Sidebar v-if="base && file" :base="base">
    <fetcDocs v-if="!isOpenApi" :base="base" :file="file" />
    <openapi-docs v-if="isOpenApi" :base="base" :file="file" />
  </Sidebar>
</template>

<script>
import fetcDocs from '../components/fetch-docs';
import OpenapiDocs from '../components/openapi-docs.vue';
import Sidebar from '../components/sidebar';

export default {
  components: {
    fetcDocs,
    Sidebar,
    OpenapiDocs
  },
  data () {
    return {
      base: undefined,
      file: undefined
    };
  },
  computed: {
    isOpenApi () {
      switch (this.base) {
        case 'mantis':
        case 'hive':
        case 'measurements':
        case 'orgs':
        case 'beetle':
        {
          return true;
        }
        default: return false;
      }
    }
  },
  watch: {
    '$route.params.file' () {
      this.file = this.$route.params.file;
    },
    '$route.params.base' () {
      this.base = this.$route.params.base;
    }
  },
  mounted () {
    this.base = this.$route.params.base;
    this.file = this.$route.params.file;
  }
};
</script>
